import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, ZoomControl, Polyline, Popup, Polygon, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet/dist/leaflet.css";
import "./index.css";
import LeftSideBar from "./components/MobileSidebar";
import 'leaflet-arrowheads';
import PopupMessageBox from "./components/PopupMessageBox";
import { ReactComponent as MapIcon } from "./assets/google-map.svg";
import { ReactComponent as PakFlagIcon } from "./assets/google-map.svg";
import { useWindowSize } from "./components/useWindowSize";
import DesktopSidebar from "./components/DesktopSidebar";


export default function App() {
  const [map, setMap] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [points, setPoints] = useState([]);
  const [leftbar, setLeftBar] = useState(false);
  const [activeRoute, setActiveRoute] = useState(null)
  const [position, setPosition] = useState(null);
  const [mobile, setMobile] = useState({
    isMobile: false,
    stage: 1
  });

  const size = useWindowSize();
  const popupElRef = useRef(null);

  const hideElement = () => {
    if (!popupElRef.current || !map) return;
    popupElRef.current._close();
    // map.closePopup();
  };


  useEffect(() => {
    if (size.width < 480) {
      setMobile({
        isMobile: true,
        stage: 1
      })
    }
  }, [size.width])


  const defaultPath = {
    color: 'red',
    weight: 7,
    opacity: 0.65
  }

  const parkingPath = {
    color: 'orange'
  }

  useEffect(() => {


    setPoints([])
    extractPoint()

    setParkings([])
    extractParking()

  }, []);

  useEffect(() => {
    extractingRoutes()
  }, [])

  useEffect(
    () => {
      if (routes.length) {
        let markerLat = (routes[activeRoute].points.map(polygon => polygon[0])).reduce((a, b) => a + b, 0) / routes[activeRoute].points.length
        let markerLong = (routes[activeRoute].points.map(polygon => polygon[1])).reduce((a, b) => a + b, 0) / routes[activeRoute].points.length
        map.target.flyTo([markerLat, markerLong], 18);
      }
    },
    [activeRoute])

  useEffect(() => {
    if (!map) return;
    // L.easyButton(`<svg xmlns="http://www.w3.org/2000/svg" className="mt-4" viewBox="0 0 21 21"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><path d="m8.5 14.5c3.3285018 0 6-2.6447124 6-5.97321429 0-3.32850184-2.6714982-6.02678571-6-6.02678571-3.32850184 0-6 2.69828387-6 6.02678571 0 3.32850189 2.67149816 5.97321429 6 5.97321429z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><circle cx="8.5" cy="8.5" fill="currentColor" r="3.5"/><g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path d="m.5 8.5h2"/><path d="m14.5 8.5h2"/><path d="m7.5 1.5h2" transform="matrix(0 1 -1 0 10 -7)"/><path d="m7.5 15.5h2" transform="matrix(0 1 -1 0 24 7)"/></g></g></svg>`, () => {
    //   map.target.locate().on("locationfound", function (e) {
    //     setPosition(e.latlng);
    //     map.target.flyTo(e.latlng, map.target.getZoom());
    //   });
    // }).setPosition('topleft').addTo(map.target);


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        //console.log(position.coords.latitude, position.coords.longitude)
      });
    }
  }, [map]);

  const extractingRoutes = async () => {

    let jsonData = await fetch('/api/road.json').then(res => res.json())

    let routesJsonData = jsonData.features.map((path) => {
      return {
        points: path.geometry.coordinates.map(v => [v[1], v[0]]),
        attr: {
          width: 10, //path.properties['stroke-width']
          color: 'red',
          bgColor: 'bg-rose-600',
          opacity: 0.85
        },
        popup: {
          title: path.properties.name,
          section: path.properties.Section,
          reason: path.properties.cause,
          description: path.properties.descript,
          date: path.properties.date,
          lastupdate: path.properties.lastupdate,
          end_date: path.properties.end_date,
        }
      }
    })

    let routesObj = routesJsonData.map((path) => {
      return {
        points: path.points,
        type: 'road',
        attr: path.attr,
        popup: path.popup
      }
    })

    let diversionData = await fetch('/api/diversion.json').then(res => res.json())

    let diversionJsonData = diversionData.features.map((path) => {
      return {
        points: path.geometry.coordinates.map(v => [v[1], v[0]]),
        attr: {
          width: 7,
          color: 'blue',
          bgColor: 'bg-blue-500',
          opacity: 0.65
        },
        popup: {
          title: path.properties.diversion,
          section: path.properties.Section,
          reason: path.properties.cause,
          description: path.properties.descript,
          date: path.properties.date,
          lastupdate: path.properties.lastupdate,
        }
      }
    })

    //console.log(`diversionJsonData`, diversionJsonData)

    let diversionObj = diversionJsonData.map((path) => {
      return {
        points: path.points,
        type: 'diversion',
        attr: path.attr,
        popup: path.popup
      }
    })


    let allRoutes = routesObj.concat(diversionObj)
    // combine routes and diversion into one array

    let allRoutesSorted = allRoutes.sort((a, b) => (a.points.length > b.points.length) ? 1 : -1)
    // console.log(`allRoutesSorted`, allRoutesSorted)

    setRoutes(allRoutesSorted)
    // set allRoutesSorted to routesAndDiversions
  }

  const extractParking = async () => {
    let parkingJsonData = await fetch('/api/parking.json').then(res => res.json())

    parkingJsonData.features.forEach((parking) => {
      const reversedMultiPolygons = parking.geometry.coordinates.map(polygon => polygon.map(v => [v[1], v[0]]))

      setParkings(prevArray => [...prevArray, reversedMultiPolygons])
    })
  }

  const extractPoint = async () => {
    let pointJsonData = await fetch('/api/point.json').then(res => res.json())

    pointJsonData.features.forEach((point) => {
      const markerPoints = [point.geometry.coordinates[1], point.geometry.coordinates[0]]

      let pinIcon = L.icon({
        iconSize: [25],
        iconUrl: `/icons/${point.properties.Name}.png`,
      });

      let obj = {
        points: markerPoints,
        icon: pinIcon,
        parking: point.properties.Name === "Parking-Parking"
      }

      setPoints(prevArray => [...prevArray, obj])
    })
  }

  const onPolylineClick = (e, key) => {
    setLeftBar(true)
    setActiveRoute(key)

    map.target.flyTo(e.latlng);
  }



  return (
    <>
      <main className="font-roboto ">
        <div className="absolute flex items-center z-20 w-full bg-transparent mt-2">
          <div className="ml-10 ">
            <a href="https://thinktransportation.net/">
              <img src="/ThinkLogo1.png" className="w-20" alt="Think Transportation" />
            </a>
          </div>

          <div className="absolute right-10">
            <a href="https://www.google.com/maps/d/viewer?mid=1HLxll_toNs7G4hqHHqqwoAWBraxqTqU" className="hover:underline"> <MapIcon className="w-10 ml-2 fill-red-600" /></a>
          </div>
        </div>

        {
          mobile.isMobile ? <LeftSideBar leftbar={ leftbar } setLeftBar={ setLeftBar } mobile={ mobile } setMobile={ setMobile } data={ routes } activeRoute={ activeRoute } setActiveRoute={ setActiveRoute } /> :
            <DesktopSidebar leftbar={ leftbar } setLeftBar={ setLeftBar } mobile={ mobile } setMobile={ setMobile } data={ routes } activeRoute={ activeRoute } setActiveRoute={ setActiveRoute } />
        }

        <div className="relative z-10">
          <MapContainer
            center={ [24.7937359, 67.0706547] }
            zoom={ 15 }
            scrollWheelZoom
            style={ { height: "100vh" } }
            zoomControl={ false }
            whenReady={ setMap }
          >

            <TileLayer
              attribution={ `${pakFlag} <a className="mx-3" href="https://thinktransportation.net/">Think Transportation</a> | <a href="https://leafletjs.com/">Leaflet</a>` }
              url='http://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
            />


            {
              routes.map((route, index) => {
                let diversion = false

                let pathOptions = {
                  color: route.attr.color,
                  weight: route.attr.width,
                  opacity: route.attr.opacity
                }

                let title = route.popup.title

                return (
                  <section key={ index }>
                    <Polyline
                      positions={ route.points }
                      pathOptions={ pathOptions }
                      eventHandlers={ { click: (e) => onPolylineClick(e, index) } }>
                      <PopupMessageBox route={ route } title={ title } bgColor={ route.attr.bgColor } />
                    </Polyline>
                  </section>
                )
              })
            }

            {
              parkings.map((parking, index) => {
                return (
                  <Polygon positions={ parking } key={ index } pathOptions={ parkingPath } />
                )
              })
            }


            {
              points.map((point, index) => {

                return (
                  <Marker position={ point.points } key={ index } icon={ point.icon }>
                    {
                      point.parking &&
                      (<Popup >
                        <div className="w-52 bg-white border rounded">
                          <div className="flex flex-col space-y-4 bg-orange-500 text-white py-1 px-2 border rounded-t">
                            <h2 className="font-bold flex-1 text-sm">Temporary Parking</h2>
                          </div>
                        </div>
                      </Popup>)
                    }

                  </Marker>
                )
              })
            }

          </MapContainer>
        </div>
      </main>
    </>
  );

}

const pakFlag = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-75 -40 120 80" version="1.1">
<title>Flag of Pakistan</title>
  <rect x="-75" y="-40" width="120" height="80" fill="#fff"/>
  <rect x="-45" y="-40" width="90" height="80" fill="#01411C"/>
  <circle r="24" fill="#fff"/>
  <circle r="22" cx="-7" cy="-40" fill="#01411C" transform="rotate(-41.63354, 45, -40)"/>
<polygon points="0,-513674 301930,415571 -488533,-158734 488533,-158734 -301930,415571" fill="#fff" transform="rotate(-41.63354) translate(16) rotate(18) scale(0.00001557408)"/>
</svg>
`








