import React, { useState } from 'react';
import { ReactComponent as MinusIcon } from "../assets/minus.svg";
import { RoadExtractor } from './RoadExtractor';
import { ToggleMenu } from './ToggleMenu';



export const DesktopSidebar = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const leftBarStyle = isExpanded ? `-translate-x-80` : 'translate-x-0';

  return (
    <>
      <ToggleMenu isExpanded={ isExpanded } setIsExpanded={ setIsExpanded }></ToggleMenu>

      {
        (
          <div className={ "absolute z-20 overflow-x-hidden left-0 -top-2 h-screen " }>
            <div className={ "scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-offWhite overflow-y-auto overflow-x-hidden bg-offWhite border-r-2 border-gray-200 transform transition-all  fixed duration-700 text-white py-2 h-full w-80 " + leftBarStyle }>

              <div className="divide-y-2 divide-gray-200 text-primary">
                <div className="mx-10 my-2">
                  <h1 className="capitalize font-semibold text-xl ml-2">Construction Zone</h1>
                </div>
                <RoadExtractor data={ props.data } activeRoute={ props.activeRoute } setActiveRoute={ props.setActiveRoute } setLeftBar={ props.setLeftBar }></RoadExtractor>
              </div>

            </div>
          </div>
        )
      }


    </>
  )




}

export default DesktopSidebar;


