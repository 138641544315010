import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { RoadExtractor } from "./RoadExtractor";


export const LeftSideBar = (props) => {

    return (
        <>
            {
                (props.mobile.isMobile && props.mobile.stage === 2) && (
                    <div className={ "absolute z-20 overflow-x-hidden bottom-pane" }>
                        <div
                            className={ "scrollbar-thin scrollbar-thumb-rose-400 scrollbar-track-offWhite overflow-y-auto overflow-x-hidden bg-offWhite border-r-2 border-gray-200 transform transition-all fixed duration-700 text-white py-2 h-64 w-full " + (!props.leftbar && "-translate-x-801") }>

                            <div className={ "absolute top-0 w-10 h-10 z-10 bottom-20 right-2 lg:-right-4 cursor-pointer transition-all transform duration-700 flex items-center justify-center " } //+ (leftbar && "translate-x-80")
                                onClick={ () => props.setMobile({ ...props.mobile, stage: 1 }) }
                            >	{ props.leftbar ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM7.994 9.422 10 6.938l2.01 2.484 2.008 2.484h-8.03z" /></svg>)
                                : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M0 10A10 10 0 1 0 10 0 10 10 0 0 0 0 10zm14.021-1.943-2.008 2.484L10 13.024l-2.01-2.483-2-2.484h8.033z" /></svg>) }

                            </div>
                            <div className="mx-3 my-2 text-primary">
                                <h1 className="capitalize  font-semibold text-xl  ml-2">Construction Zone</h1>
                            </div>
                            <RoadExtractor data={ props.data } activeRoute={ props.activeRoute } setActiveRoute={ props.setActiveRoute } setLeftBar={ props.setLeftBar }></RoadExtractor>

                        </div>
                    </div>
                )
            }

            {
                (props.mobile.isMobile && props.mobile.stage === 1) && (
                    <div className="fixed bottom-0 z-20 bg-white w-full" onClick={ () => props.setMobile({ ...props.mobile, stage: 2 }) }>
                        <button className="p-4 w-full">
                            <div className="ml-icon rq6Kqd"></div>Tap to see construction zones
                        </button>
                    </div>
                )
            }
        </>
    )




}

export default LeftSideBar;


