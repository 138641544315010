import React from 'react';
import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";

export function ToggleMenu(props) {
  return (<div className='relative z-50'>
    <button className=" absolute left-0 mx-2 outline-none focus:outline-none" onClick={ () => props.setIsExpanded(!props.isExpanded) }>
      { props.isExpanded ? <MenuIcon className='fill-slate-800 w-7 h-7 mt-3 ' /> : <CrossIcon className='fill-rose-600 mt-3 mx-auto' /> }
    </button>
  </div>);
}
