import { ReactComponent as MinusIcon } from "../assets/minus.svg";

export function RoadExtractor(props) {
  return (
  <div className="divide-y-2 divide-gray-200 m-0 p-0 text-primary">  
    
    { 
      props.data.map((route, index) => { 
        return (
          route.type === 'road' && 
            <div className={ "p-2 border-b cursor-pointer " + (props.activeRoute === index ? "bg-rose-600 text-white" : "text-primary") } key={ index } 
            onClick={ () => {
              props.setActiveRoute(index);
              props.setLeftBar(true);
            }}>
              <section>
                <div className="flex ">
                  <div>
                    <MinusIcon className={ "mx-3 w-5 h-5 " + (props.activeRoute === index ? "fill-gray-100" : "fill-rose-600") } />
                  </div>
                  <div>
                    <h2 className="capitalize text-sm"> { `${route.popup.title} ${route.popup.section}` } </h2>
                  </div>
                </div>
              </section>
            </div>
        )
      }
    )}

  </div>
  );
}